import { apiClient } from "./api";

export default {
  getEventsList() {
    return apiClient.post(`/events/list`, { action: "list" });
  },
  createEvent(payload: any) {
    return apiClient.post(`/events/create`, payload);
  },
  updateEvent(payload: any) {
    return apiClient.post(`/events/update`, payload);
  },
  deleteEvent(payload: any) {
    return apiClient.post(`/events/delete`, payload);
  },
};
