import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store/store';
import { checkAuthentication } from './reducers/auth_reducer';
import { routes_arr, routes_arr_no_auth } from './Helpers/routes_arr';
import SideBar from './Layouts/SideBar';
import Header from './Layouts/Header';
import { CssBaseline, Box, AppBar, Drawer } from '@mui/material';
import { styled } from '@mui/system';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const drawerWidth = 240;

interface MainContentProps {
  isAuthenticated: boolean;
};

const MainContent = styled(Box)<MainContentProps>(
  ({ theme, isAuthenticated }) => ({
    flexGrow: 1,
    padding: isAuthenticated ? theme.spacing(3) : 0,
    marginTop: isAuthenticated ? theme.spacing(6) : 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: isAuthenticated ? '100%' : `calc(100% - ${drawerWidth}px)`,
    justifyContent: 'start',
    height: '100vh',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      width: '100%',
    },
  })
);

function App() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state: RootState) => state.authReducer);

  useEffect(() => {
    dispatch(checkAuthentication());
  }, [dispatch]);

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#f5f5f5' }}>
      <CssBaseline />
      {isAuthenticated && (
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Header />
        </AppBar>
      )}
      {isAuthenticated && (
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
        >
          <SideBar />
        </Drawer>
      )}
      <MainContent isAuthenticated={isAuthenticated}>
        <Routes>
          {isAuthenticated ? (
            <>
              {routes_arr.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.component />}
                />
              ))}
              <Route path="*" element={<Navigate to="/events" />} />
            </>
          ) : (
            <>
              {routes_arr_no_auth.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.component />}
                />
              ))}
              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
        </Routes>
      </MainContent>
      <ToastContainer />
    </Box>
  );
};

export default App;