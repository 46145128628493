import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink } from "react-router-dom";
import { AppLogo } from "../Helpers/constants";
import s from "./layouts_style.module.css";
import menu_items_arr from "../Helpers/menu_items";
import { Box } from "@material-ui/core";
import help_icon from "../Assets/help_icon.png";
import { useTranslation } from "react-i18next";

const drawerWidth = 260;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: "#FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    listItem: {
      margin: "0 auto", // Centers the list item horizontally
    },
    helpIconContainer: {
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(2),
    },
    helpIcon: {
      width: "100%",
      height: "auto",
    },
  })
);

const Sidebar: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AppLogo />
          </div>
          <List
            style={{
              padding: "10px",
            }}
          >
            {menu_items_arr.map((item, index) => {
              const Icon = item.icon; // Component type
              return (
                <NavLink
                  key={index + item.path}
                  to={item.path}
                  className={({ isActive }) =>
                    isActive ? s.activeItem : s.navLinkItem
                  }
                >
                  <div className={s.navLinkItemDiv}>
                    <ListItem
                      button
                      onClick={handleClick}
                      className={classes.listItem}
                    >
                      <ListItemIcon>
                        <Icon />
                      </ListItemIcon>
                      <ListItemText primary={t(`menu.${item.name}`, item.name)} />
                    </ListItem>
                  </div>
                </NavLink>
              );
            })}
          </List>
        </div>
        <Box className={classes.helpIconContainer}>
          <img src={help_icon} alt="Help Icon" className={classes.helpIcon} />
        </Box>
      </Drawer>
    </div>
  );
};

export default Sidebar;
