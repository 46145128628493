// store/index.ts
import { createStore, combineReducers } from 'redux';
import authReducer from '../reducers/auth_reducer';
import hallsReducer from '../reducers/hall_reducer';
import eventsReducer from '../reducers/event_reducer';

const rootReducer = combineReducers({
    authReducer: authReducer,
    hallsReducer: hallsReducer,
    eventsReducer: eventsReducer
});

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer);

export default store;
