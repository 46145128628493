import React from "react";
import { Container, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import bg_image from "./../../Assets/login_background_2.png";
import moviesImg from "./../../Assets/moviesImage.png";
import { useNavigate } from "react-router-dom";

// Define styles using Material-UI makeStyles
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  viewPart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center", // Center vertically
    height: "100%", // Fill the height of the parent container
    width: "100%", // Fill the width of the parent container
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#4D67EB",
  },
  viewRightPart: {
    position: "relative",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
  },
  errorMessage: {
    color: "red",
  },
  backSymbol: {
    width: "100%",
    cursor: "pointer",
  },
  titleDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
    fontSize: "30px",
    letterSpacing: "2px",
    marginTop: "10px",
  },
  moviesImg: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "60%",
    height: "50%",
    position: "absolute",
    top: "15%",
    left: "28%",
  },
  linkLabels: {
    color: "#4D67EB",
    cursor: "pointer",
  },
}));

interface props {
  children: React.ReactNode;
  withBack: boolean;
}
const AuthPageWrapperComponent: React.FC<props> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { withBack } = props;

  return (
    <Grid container component="main" className={classes.root}>
      <Grid
        item
        xs={12}
        sm={6}
        component={Paper}
        elevation={6}
        className={classes.viewPart}
        square
      >
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            {withBack ? (
              <div className={classes.backSymbol} onClick={() => navigate(-1)}>
                ←
              </div>
            ) : null}
            {props.children}
          </div>
        </Container>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.viewRightPart}>
        <img src={bg_image} alt="Movies Image" className={classes.image} />
        <img src={moviesImg} alt="Movies Image" className={classes.moviesImg} />
      </Grid>
    </Grid>
  );
};

export default AuthPageWrapperComponent;
