import { ReactNode } from "react";
import { EventsIcon, SeatPlanIcon } from "./constants";
interface MenuItem {
  name: string;
  icon: React.ComponentType<any>;
  path: string;
}

type MenuItemsArray = MenuItem[];

const menu_items_arr: MenuItemsArray = [
  {
    name: 'Events',
    icon: EventsIcon,
    path: '/events'
  },
  {
    name: 'Halls',
    icon: EventsIcon,
    path: '/halls'
  },
  {
    name: 'Seating Plans',
    icon: SeatPlanIcon,
    path: '/seating_plans'
  }
];

export default menu_items_arr;
