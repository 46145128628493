// src/components/Login.tsx
import React from "react";
import { Typography, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthPageWrapperComponent from "../../Components/AuthenticationComponents/AuthPageWrapperComponent";
import { NavLink, useNavigate } from "react-router-dom";
import { login } from "../../reducers/auth_reducer";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { login as loginService } from "../../services/auth_service";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  viewPart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#4D67EB",
  },
  viewRightPart: {
    position: "relative",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
  },
  errorMessage: {
    color: "red",
    fontSize: "12px",
  },
  backSymbol: {
    width: "100%",
    cursor: "pointer",
  },
  titleDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
    fontSize: "135%",
    letterSpacing: "2px",
    marginTop: "10px",
  },
  moviesImg: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "60%",
    height: "50%",
    position: "absolute",
    top: "10%",
    left: "28%",
  },
  linkLabels: {
    color: "#4D67EB",
    cursor: "pointer",
  },
}));

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
});

const LoginPage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (values: any) => {
    try {
      const response = await loginService(values.email, values.password);
      if (response?.status?.status !== "error") {
        dispatch(
          login({
            token: response.jwt,
            user: { full_name: response.full_name },
          })
        );
        localStorage.setItem("token", response.jwt);
        navigate("/events");
      } else {
        Swal.fire({
          title: "Error",
          text: response.status.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Invalid credentials",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <AuthPageWrapperComponent withBack={false}>
      <div className={classes.formContainer}>
        <div className={classes.titleDiv}>Welcome to eTicketing</div>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form className={classes.form}>
              <Field
                as={TextField}
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                error={errors.email && touched.email ? true : false}
                autoFocus
              />
              <ErrorMessage
                name="email"
                component="div"
                className={classes.errorMessage}
              />
              <Field
                as={TextField}
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={errors.password && touched.password ? true : false}
              />
              <ErrorMessage
                name="password"
                component="div"
                className={classes.errorMessage}
              />
              <Typography align="right" className={classes.linkLabels}>
                <NavLink to="/forgot_password">Forgot Password?</NavLink>
              </Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >
                Sign In
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </AuthPageWrapperComponent>
  );
};

export default LoginPage;
