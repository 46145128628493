import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  makeStyles,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import halls_service from "../../services/halls_service";
import seating_plan_service from "../../services/seating_plan_service";
import { setHallsList } from "../../reducers/hall_reducer";
import { useTranslation } from "react-i18next";
import { data_example } from "./data_example";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    paddingBottom: theme.spacing(2),
  },
  container: {
    width: "98%",
    backgroundColor: "#fff",
    padding: "20px",
    height: "95%",
  },
  titlesContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  addButton: {
    backgroundColor: "#4D67EB",
    fontSize: "12px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#3755a3",
    },
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
  containerTable: {
    width: "100%",
    marginTop: theme.spacing(2),
    height: "95vh",
    overflowY: "auto",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: "1px solid #ddd",
    height: "55px",
  },
  rowLeft: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#191919",
  },
  rowId: {
    fontSize: "14px",
    color: theme.palette.text.secondary,
  },
  rowRight: {
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    color: "#4D67EB",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#4D67EB",
    padding: theme.spacing(4),
    borderRadius: "8px",
    outline: "none",
    width: "500px",
    textAlign: "center",
  },
  modalTitle: {
    color: "#fff",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    fontSize: "20px",
  },
  modalDescription: {
    color: "#fff",
    fontSize: "14px",
    marginBottom: theme.spacing(4),
  },
  selector: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    marginBottom: theme.spacing(4),
    width: "100%",
  },
  cancelButton: {
    backgroundColor: "#fff",
    color: "#4D67EB",
    marginRight: theme.spacing(2),
  },
  continueButton: {
    backgroundColor: "#8AE06A",
    color: "#fff",
  },
  loaderBackdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

interface Hall {
  hall_id: string;
  hasseating: string;
  [key: string]: any;
}

interface SeatingPlan {
  seating_id: string;
  hall_id: string;
  seating_name: string;
  seating_json: string;
  hallname: string;
}

const SeatingPlansPage: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Loader state
  const [selectedHall, setSelectedHall] = useState("");
  const [seatingPlansList, setSeatingPlansList] = useState<SeatingPlan[]>([]);
  const navigate = useNavigate();
  const { hallsReducer } = useSelector((state: RootState) => state);
  const { hallsList } = hallsReducer;
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    halls_service
      .getHallsList()
      .then((res) => {
        const hallsList = res.data.status.halls;
        if (hallsList.length) {
          dispatch(setHallsList(hallsList));
          setSelectedHall(hallsList[0]?.hall_id || "");
        }
      })
      .catch(() => {
        setLoading(false); // Deactivate loader in case of error
      });

    seating_plan_service
      .getSeatingPlansList()
      .then((res) => {
        setSeatingPlansList(res.data.status.seating);
        setLoading(false); // Deactivate loader
      })
      .catch(() => {
        setLoading(false); // Deactivate loader in case of error
      });
  }, [dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleHallChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedHall(event.target.value as string);
  };

  const handleContinue = () => {
    navigate(`/seating_plans/create_seating_plan/${selectedHall}`);
  };

  const handleEdit = (seatingPlan: SeatingPlan) => {
    navigate(`/seating_plans/create_seating_plan/${seatingPlan.hall_id}/${seatingPlan.seating_id}`, {
      state: { appliedCells: JSON.parse(seatingPlan.seating_json),
      seatingPlanName: seatingPlan.seating_name },
    });
  };

  const handleDelete = (seatingPlan: SeatingPlan) => {
    seating_plan_service
      .deleteSeatingPlan({ seating_id: seatingPlan.seating_id })
      .then(() => {
        setSeatingPlansList((prev) =>
          prev.filter((plan) => plan.seating_id !== seatingPlan.seating_id)
        );
      });
  };

  return (
    <Box className={classes.root}>
      <Backdrop className={classes.loaderBackdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box className={classes.container}>
        <Box className={classes.buttonContainer}>
          <Box className={classes.titlesContainer}>
            <Typography variant="body1" style={{ fontWeight: 500, fontSize: "20px" }}>
              Seating Plan Configuration
            </Typography>
            <Typography>{seatingPlansList.length} seating plans</Typography>
          </Box>
          <Button
            variant="contained"
            className={classes.addButton}
            size="small"
            onClick={handleOpen}
          >
            <AddCircleOutlineIcon className={classes.addIcon} />
            Add New
          </Button>
        </Box>
        <Box className={classes.containerTable}>
          {seatingPlansList.map((seatingPlan) => (
            <Box key={seatingPlan.seating_id} className={classes.row}>
              <Box>
                <Typography className={classes.rowLeft}>
                  {seatingPlan.seating_name}
                </Typography>
                <Typography className={classes.rowId}>
                  {seatingPlan.hallname}
                </Typography>
              </Box>
              <Box className={classes.rowRight}>
                <svg
                  style={{
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleEdit(seatingPlan)}
                >
                  <path
                    d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13"
                    stroke="#4D67EB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.04 3.01976L8.16 10.8998C7.86 11.1998 7.56 11.7898 7.5 12.2198L7.07 15.2298C6.91 16.3198 7.68 17.0798 8.77 16.9298L11.78 16.4998C12.2 16.4398 12.79 16.1398 13.1 15.8398L20.98 7.95976C22.34 6.59976 22.98 5.01976 20.98 3.01976C18.98 1.01976 17.4 1.65976 16.04 3.01976Z"
                    stroke="#4D67EB"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.91 4.1499C15.58 6.5399 17.45 8.4099 19.85 9.0899"
                    stroke="#4D67EB"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <svg
                  style={{
                    cursor: "pointer",
                  }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleDelete(seatingPlan)}
                >
                  <path
                    d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                    stroke="#4D67EB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                    stroke="#4D67EB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.8499 9.14014L18.1999 19.2101C18.0899 20.7801 17.9999 22.0001 15.2099 22.0001H8.7899C5.9999 22.0001 5.9099 20.7801 5.7999 19.2101L5.1499 9.14014"
                    stroke="#4D67EB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.3301 16.5H13.6601"
                    stroke="#4D67EB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.5 12.5H14.5"
                    stroke="#4D67EB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Modal open={open} onClose={handleClose} className={classes.modal}>
        <Box className={classes.modalContent}>
          <Typography className={classes.modalTitle}>Select Hall</Typography>
          <Typography className={classes.modalDescription}>
            Please select one of the halls to proceed with seating plan creation
          </Typography>
          <FormControl variant="outlined" className={classes.selector}>
            <InputLabel id="hall-select-label">Hall</InputLabel>
            <Select
              labelId="hall-select-label"
              id="hall-select"
              value={selectedHall}
              onChange={handleHallChange}
              label="Hall"
            >
              {hallsList.map((hall: Hall) => (
                <MenuItem key={hall.hall_id} value={hall.hall_id}>
                  {hall[`hallName_${language}`] as string}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box>
            <Button
              variant="contained"
              className={classes.cancelButton}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={classes.continueButton}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default SeatingPlansPage;